/* Example Styles for React Tags*/
.app {
    padding: 40px;
    text-align: center;
}

/* Example Styles for React Tags*/

.container {
    margin: auto;
    width: 50%;
}

.ReactTags__tags {
    position: relative;
}

.ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #f88d8d;
    color: #fff;
    border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
    border-radius: 2px;
    display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    border: 1px solid #eee;
    min-width: 150px;
    outline: none;
    border-radius: 30px;

}

.ReactTags__editInput {
    border-radius: 1px;
}

.ReactTags__editTagInput {
    display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #3d8dbc;
    color: white;
    font-size: 12px;
    display: inline-block;
    margin: 0 5px;
    border-radius: 30px;
    padding: 5px 5px 5px 15px;
}

.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
    position: absolute;
}

.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
}

.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}

.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
}

.pickerStyle {
    width: 100% !important;
}

.ant-picker {
    width: 100% !important;
}

.cursor_style {
    cursor: pointer;
}

.logoHeader {
    margin: -15px -15px -15px 70px !important;
}

.sc-fzqBZW.cGkIHG {
    display: none !important;
}

.dropdown-menu-account {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}

/*header.sc-fzqNJr.kwRiGp.rdt_TableHeader {*/
/*  min-height: auto!important;*/
/*}*/
.container-action {
    text-align: center;
}

.script-style {
    height: 25%;
    overflow: auto;
}

.script-data-style {
    width: 100%;
}

.custom-style {
    display: contents !important;
    width: 1000px !important;
}

.customNumPad-container {

}

.customNumPad-phone {
    position: fixed;
    bottom: 60px;
    right: 0;
    width: 80px;
}

.customNumpad-timer {
    position: fixed;
    bottom: 85px;
    right: 65px;
    padding-right: 10px;
    background-color: white;
    border: 2px solid black;
    width: auto;
    height: 25px;
    padding-left: 5px;
    border-radius: 10px 0 0 10px;
}
.customNumpad-text-open {
    visibility: visible;
    transform: translateX(0);
}
.customNumpad-text-close {
    visibility: hidden;
    transform: translateX(100%);
}

.isVisible{
    visibility: visible;
}
.isHidden{
    visibility: hidden;
}
.customNumpad-text {
    position: fixed;
    right : 0;
    max-width : 420px;
    width: auto;
    transition: all .3s ease-out;
    bottom : 20px;
}
.customNumpad-arrowRight {
    position: absolute;
    left: -30px;
    top: 45%;
    width: 80px;
    height: 80px;
    background: white;
    border-radius : 50%;
}
.customNumpad-Arrow {
    position: absolute;
    top : 30px;
    left : 10px
}
@media only screen and (max-width: 449px) {
    .customNumpad-text {
        max-width : 450px;
    }
    .customNumpad-text-open {
        visibility: visible;
        transform: translateY(0);
    }
    .customNumpad-text-close {
        visibility: hidden;
        transform: translateY(100%);
    }
}
